<template>
    <svg
        id="a"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1981.1 370.15"
    >
        <defs>
            <linearGradient
                id="c"
                x1="3176.13"
                y1="-15.71"
                x2="3176.13"
                y2="-191.75"
                gradientTransform="translate(5004.63 176) rotate(-180)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#00add4" />
                <stop offset=".1" stop-color="#01a8cd" stop-opacity=".97" />
                <stop offset=".25" stop-color="#049abc" stop-opacity=".87" />
                <stop offset=".42" stop-color="#0a83a0" stop-opacity=".71" />
                <stop offset=".63" stop-color="#116478" stop-opacity=".49" />
                <stop offset=".85" stop-color="#1b3d46" stop-opacity=".21" />
                <stop offset="1" stop-color="#231f20" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="d"
                x1="3500.99"
                y1="-15.71"
                x2="3500.99"
                y2="-191.75"
                gradientTransform="translate(-1791.74 176) scale(1 -1)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#00add4" />
                <stop offset=".1" stop-color="#01a8cd" stop-opacity=".97" />
                <stop offset=".25" stop-color="#049abc" stop-opacity=".87" />
                <stop offset=".42" stop-color="#0a83a0" stop-opacity=".71" />
                <stop offset=".63" stop-color="#116478" stop-opacity=".49" />
                <stop offset=".85" stop-color="#1b3d46" stop-opacity=".21" />
                <stop offset="1" stop-color="#231f20" stop-opacity="0" />
            </linearGradient>
        </defs>
        <g>
            <g>
                <path
                    d="M94.6,328.3V87.6H0v-44.8h242.3v44.8h-94.6v240.6c-.1.1-53.1.1-53.1.1Z"
                    style="stroke-width: 0px"
                />
                <path
                    d="M364.2,331.1c-18.5,0-34.8-3.5-49-10.4s-25.2-17.6-33.2-31.8c-8-14.3-12-32.3-12-54.1v-124.4h51.4v117.9c0,19.3,4.5,33.8,13.4,43.4s21.5,14.5,37.5,14.5c12,0,22.4-2.5,31.4-7.3,9-4.9,15.9-12.2,20.8-22,4.9-9.8,7.3-21.9,7.3-36.3v-110.2h51v217.8h-48.6v-59.1l8.6,18.4c-7.3,13.9-18.1,24.6-32.2,32.2-14,7.6-29.5,11.4-46.4,11.4h0Z"
                    style="stroke-width: 0px"
                />
                <path
                    d="M520.9,152.8v-40.8h145.3v40.8h-145.3ZM631.8,331.1c-23.9,0-42.5-6.2-55.5-18.6s-19.6-30.5-19.6-54.5V62.3h50.6v194.2c0,10.3,2.7,18.4,8.2,24.1,5.4,5.7,13,8.6,22.8,8.6,11.1,0,20.4-3,27.7-9l14.7,36.3c-6.3,4.9-13.8,8.6-22.4,11-8.7,2.4-17.5,3.6-26.5,3.6h0Z"
                    style="stroke-width: 0px"
                />
                <path
                    d="M813.4,331.1c-22,0-41.8-4.9-59.4-14.5s-31.3-22.9-41.4-39.7c-10.1-16.8-15.1-36-15.1-57.5s5-41.3,15.1-58c10.1-16.6,23.8-29.7,41.2-39.3s37.3-14.5,59.5-14.5,42.6,4.9,60.2,14.5c17.6,9.6,31.2,22.8,41.2,39.3,9.9,16.6,14.9,35.9,14.9,58s-4.9,41-14.9,57.7c-9.9,16.7-23.7,29.9-41.4,39.6-17.7,9.6-37.7,14.4-59.9,14.4h0ZM813.4,287.9c12.5,0,23.7-2.8,33.5-8.3,9.8-5.6,17.5-13.6,23.1-24.1s8.3-22.5,8.3-36.1-2.8-25.9-8.3-36.1c-5.6-10.2-13.3-18.1-23.1-23.7-9.8-5.6-20.8-8.3-33.1-8.3s-23.2,2.8-33.1,8.3c-9.8,5.6-17.6,13.4-23.2,23.7-5.7,10.2-8.6,22.3-8.6,36.1s2.9,25.6,8.6,36.1c5.7,10.5,13.4,18.5,23.2,24.1,9.8,5.5,20.7,8.3,32.7,8.3Z"
                    style="stroke-width: 0px"
                />
                <path
                    d="M978.9,328.3V110.4h48.6v59.9l-5.7-17.6c6.3-14.7,16.5-25.9,30.6-33.6s31.4-11.6,51.8-11.6v48.6c-1.9-.3-3.8-.5-5.7-.6-1.9-.2-3.7-.2-5.3-.2-19.5,0-35.1,5.6-46.5,16.7-11.5,11.1-17.2,28.1-17.2,51v105.3h-50.6Z"
                    style="stroke-width: 0px"
                />
                <path
                    d="M1148.2,328.3V110.4h50.6v217.8l-50.6.1h0Z"
                    style="stroke-width: 0px"
                />
                <path
                    d="M1368.4,331.1c-24.2,0-45.3-4.9-63.4-14.5s-32-22.9-42-39.6c-9.9-16.7-14.9-35.9-14.9-57.7s4.8-41,14.5-57.7c4.4-7.6,9.5-14.4,15.4-20.5,1.7-1.8,4.4-4.4,7.7-7.3,0,0,7.7-6.6,16.8-11.7,25.8-14.6,58.1-14.5,58.1-14.5,21.2,0,40.1,4.7,56.7,14.1s29.6,22.5,39.2,39.3c9.5,16.9,14.3,36.8,14.3,59.9,0,1.9-.1,4.3-.2,7.2-.2,2.9-.3,5.4-.6,7.6h-181.5v-33.9h154.6l-20.4,10.6c.3-12.2-2.2-23.2-7.6-32.8-5.3-9.6-12.6-17.2-21.9-22.4-9.2-5.3-20.1-7.9-32.6-7.9s-23.2,2.6-32.8,7.9c-9.6,5.3-17.1,12.9-22.3,22.7-5.2,9.8-7.7,21.1-7.7,33.9v8.2c0,13,2.9,24.6,8.7,34.7,5.9,10.1,14.2,17.9,25.1,23.4,10.9,5.6,23.5,8.3,37.9,8.3,12.2,0,23.2-2.1,33.1-6.1,9.8-4,18.5-10.1,26.1-18l27.7,31.8c-10.1,11.5-22.5,20.1-37.3,26.1-14.7,5.9-31.7,8.9-50.7,8.9h0Z"
                    style="stroke-width: 0px"
                />
            </g>
            <path
                d="M1135.6,29.9v38.8c0,.8.4,1.5,1.1,1.9l35.8,20.7c.7.4,1.5.4,2.2,0l35.8-20.7c.7-.4,1.1-1.1,1.1-1.9V29.9c0-1.7-1.8-2.7-3.3-1.9l-33.6,19.4c-.7.4-1.5.4-2.2,0l-33.6-19.4c-1.5-.8-3.3.2-3.3,1.9h0Z"
                style="fill: #00add4; stroke-width: 0px"
            />
        </g>
        <g id="b" data-name="New">
            <path
                d="M1663.1,6.1v108.1c0,2.2,1.2,4.2,3.1,5.3l99.7,57.6c1.9,1.1,4.2,1.1,6.1,0l99.7-57.6c1.9-1.1,3-3.1,3-5.3V6.1c0-4.7-5.1-7.6-9.2-5.3l-93.6,54c-1.9,1.1-4.2,1.1-6.1,0L1672.2.8c-4-2.3-9.1.6-9.1,5.3Z"
                style="fill: #00add4; stroke-width: 0px"
            />
            <g>
                <path
                    d="M1978.1,186l-93.6-54.1c-1.9-1.1-4.2-1.1-6.1,0l-99.7,57.6c-.9.6-1.7,1.3-2.2,2.2h0l9.8,5.7,95.2,55v3.5c0-2.2,1.2-4.2,3-5.3l93.6-54.1h0c4-2.3,4-8.2,0-10.5h0Z"
                    style="fill: #0034aa; stroke-width: 0px"
                />
                <path
                    d="M1786.2,197.4l-9.8-5.7h0c-.5.9-.8,2-.8,3.1v115.2c0,2.2,1.2,4.2,3,5.3l93.6,54c4.1,2.4,9.2-.6,9.2-5.3v-111.6l-95.2-55h0Z"
                    style="fill: #0034aa; stroke-width: 0px"
                />
                <path
                    d="M1786.2,197.4l-9.8-5.7h0c-.5.9-.8,2-.8,3.1v115.2c0,2.2,1.2,4.2,3,5.3l93.6,54c4.1,2.4,9.2-.6,9.2-5.3v-111.6l-95.2-55h0Z"
                    style="fill: url(#c); stroke-width: 0px"
                />
            </g>
            <g>
                <path
                    d="M1559.8,186l93.6-54.1c1.9-1.1,4.2-1.1,6.1,0l99.7,57.6c1,.6,1.7,1.3,2.2,2.2h0l-9.8,5.7-95.2,55v3.5c0-2.2-1.2-4.2-3.1-5.3l-93.6-54h0c-4-2.4-4-8.3.1-10.6h0Z"
                    style="fill: #0034aa; stroke-width: 0px"
                />
                <path
                    d="M1751.6,197.4l9.8-5.7h0c.5.9.8,2,.8,3.1v115.2c0,2.2-1.2,4.2-3.1,5.3l-93.6,54c-4.1,2.4-9.2-.6-9.2-5.3v-111.6l95.3-55h0Z"
                    style="fill: #0034aa; stroke-width: 0px"
                />
                <path
                    d="M1751.6,197.4l9.8-5.7h0c.5.9.8,2,.8,3.1v115.2c0,2.2-1.2,4.2-3.1,5.3l-93.6,54c-4.1,2.4-9.2-.6-9.2-5.3v-111.6l95.3-55h0Z"
                    style="fill: url(#d); stroke-width: 0px"
                />
            </g>
        </g>
    </svg>
</template>
